

import {
  defineComponent,
  ref,
} from "vue";
import { useRoute } from "vue-router";
import webRefereeePage from "@/components/webRefereePage.vue";

export default defineComponent({
  components: {
    webRefereeePage
  },
  setup() {
    const route = useRoute();
    const accessToken = ref<string>(route.params.token as string);
  
    return {
      accessToken,
    };
  },
});
